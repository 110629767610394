import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import parse from "html-react-parser"

const OurTeam = props => {
  const { coFounders: { founders }, teamMembers: { members }} = useStaticQuery(graphql`
    query OurTeamQuery {
      coFounders: allWpTeamMember(filter: {categories: {nodes: {elemMatch: {slug: {eq: "cofounders"}}}}}, sort: {fields: memberAcf___joiningdate, order: ASC}) {
        founders: edges {
          node {
            featuredImage {
              node {
                sourceUrl
              }
            }
            id
            title
            memberAcf {
              designation
              linkedinUrl
            }
          }
        }
      }
      teamMembers: allWpTeamMember(filter: {categories: {nodes: {elemMatch: {slug: {eq: "teammember"}}}}}, sort: {fields: memberAcf___joiningdate, order: ASC}) {
        members: edges {
          node {
            featuredImage {
              node {
                sourceUrl
              }
            }
            id
            title
            memberAcf {
              designation
              joiningdate
              linkedinUrl
            }
          }
        }
      }
    }
  `)
  const isEmpty = members.length === 0;
  const classes = useStyles();

  if (isEmpty) {
    return <span />
  }

  const renderMemberCard = (member) => {
    return (
      <Card className={classes.card}>
        <CardActionArea
          component="a"
          href={member.memberAcf.linkedinUrl}
          target="_blank"
        >
          <div
            className={classes.cardImage}
            style={{
              background: `url(${member.featuredImage.node.sourceUrl}) center center no-repeat`,
              backgroundSize: "cover",
            }}
          />
          {/* <div className={classes.cardOverlay} /> */}
          <CardContent className={classes.cardContent}>
            <div className={`${classes.withOverlay} move-to-top`}>
              <Typography variant="h6">
                {member.title}
              </Typography>   
              <Typography
                variant="body2"
                className={classes.textPrimary}
              >
                {member.memberAcf.designation}
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }

  // console.log(events)
  return (
    <div className={`${classes.root} alignwide`}>
      <h2>Our Team</h2>
      <Grid container spacing={4}>
        {founders.map(memberObj => {
          const member = memberObj.node
          return (
            <Grid item key={member.id} xs={12} lg={3}>
              {renderMemberCard(member)}
            </Grid>
          )
        })}
      </Grid>
      <br />
      <Grid container spacing={4}>
        {members.map(memberObj => {
          const member = memberObj.node
          return (
            <Grid item key={member.id} xs={12} lg={3}>
              {renderMemberCard(member)}
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '50px 0px 100px 0px',
    overflowX: 'hidden',
  },
  card: {
    borderRadius: 4,
    position: 'relative',
    top: 0,
    transition: 'top ease 0.3s',
    boxShadow: '0 0.625rem 1.25rem 0 rgb(0, 0, 0 , 0.1)',
    '& .move-to-top': {
      transition: 'bottom ease 0.3s',
    },
    '&:hover': {
      top: '-12px',
    '& .move-to-top': {
        bottom: 0
      }
    }
  },
  withOverlay: {
    position: "absolute",
    // height: 64,
    left: 0,
    right: 0,
    bottom: -25,
    padding: '8px 16px',
    background: "rgba(0, 0, 0, 0.6)",
    zIndex: 2,
    // borderRadius: '8px 8px 0 0'
  },
  cardContent: {
    minHeight: 280,
    padding: '6px 16px',
    position: "relative",
    zIndex: 3,
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  cardImage: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  textPrimary: {
    color: theme.palette.grey[300],
  },
}));


export default OurTeam;
