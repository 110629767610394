import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import parse from "html-react-parser"

let months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const UpcomingEvents = props => {
  const { allWpEvent: { events } } = useStaticQuery(graphql`
    query UpcomingEventsQuery {
      allWpEvent(filter: {categories: {nodes: {elemMatch: {slug: {eq: "upcoming-events"}}}}}, limit: 4, sort: {fields: eventAcf___eventDate, order: ASC}) {
        events: edges {
          node {
            id
            title
            slug
            uri
            featuredImage {
              node {
                sourceUrl
              }
            }
            excerpt
            eventAcf {
              eventDate
              eventTime
              hostName
            }
          }
        }
      }
    }
  `)
  const isEmpty = events.length === 0;
  const classes = useStyles();

  if (isEmpty) {
    return <span />
  }
  // console.log(events)
  return (
    <div className={`bg-muted padding-top-bottom-60`}>
      <div className={`${classes.root} alignwider`}>
        <h2>Upcoming Events</h2>
        <Grid container spacing={4}>
          {events.map(eventObj => {
            const event = eventObj.node
            const eDate = new Date(event.eventAcf.eventDate)
            const day = eDate.getDate()
            const mon = months[eDate.getMonth()]
            const yr = eDate.getFullYear()
            return (
              <Grid item key={event.id} xs={12} lg={3}>
                <Card className={classes.card}>
                  <CardActionArea
                    component={Link}
                    to={`/events/${event.slug}`}
                  >
                    <div
                      className={classes.cardImage}
                      style={{
                        background: `url(${event.featuredImage.node.sourceUrl}) center center no-repeat`,
                        backgroundSize: "cover",
                      }}
                    />
                    <div className={classes.cardOverlay} />
                    <CardContent className={classes.cardContent}>
                      <Typography variant="h2">{day}</Typography>
                      <Typography variant="button" gutterBottom>
                        {mon} {yr}
                      </Typography>
                      <br />
                      <br />
                      <Typography variant="h5" gutterBottom>
                        {event.title}
                      </Typography>   
                      <Typography
                        variant="subtitle1"
                        className={classes.textPrimary}
                        gutterBottom
                      >
                        {event.eventAcf.eventTime}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.textPrimary}
                        gutterBottom
                      >
                        Speaker: {event.eventAcf.hostName}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // paddingTop: 50,
    // paddingBottom: 50,
    // overflowX: 'hidden',
  },
  card: {
    borderRadius: 8,
    // boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
    position: 'relative',
    top: 0,
    transition: 'top ease 0.3s',
    boxShadow: '0 0.625rem 1.25rem 0 rgb(0, 0, 0 , 0.1)',
    '&:hover': {
      top: '-12px'
    }
  },
  cardOverlay: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.8)",
    zIndex: 2,
  },
  cardContent: {
    minHeight: 240,
    padding: 24,
    position: "relative",
    zIndex: 3,
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  cardImage: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  textPrimary: {
    color: theme.palette.grey[300],
  },
}));


export default UpcomingEvents;
