import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import parse from "html-react-parser"
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import hexToRgba from 'hex-to-rgba';

import ScrollSpyTabs from './ScrollSpyTabs';
import Cardback from "../assets/images/green-back.svg";

const Services = props => {

  const { allWpCategory: { categories }} = useStaticQuery(graphql`
    query serviceCategories {
      allWpCategory(filter: {wpParent: {node: {slug: {eq: "our-services"}}}}, sort: { fields: [databaseId], order: ASC }) {
        categories: edges {
          node {
            name
            databaseId
            description
            slug
            posts {
              nodes {
                id
                title
                slug
                uri
                excerpt
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
                tags {
                  nodes {
                    id
                    name
                  }
                }
              }
            }
            projects {
              nodes {
                id
                title
                slug
                uri
                excerpt
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
                projectAcf {
                  showDetailedPage
                  clientName
                  clientLogo {
                    sourceUrl
                  }
                  uiBackgroundColor
                  uiTextColor
                }
              }
            }
          }
        }
      }
    }
  `)

  const isEmpty = categories.length === 0;
  const classes = useStyles();

  if (isEmpty) {
    return <span />
  }

  const renderPostsAndProjects = category => (
    <div className="margin-bottom-50">
      <h2 className="margin-top-0 margin-bottom-10">
        {category.name}
      </h2>
      <Typography variant="subtitle1">{category.description}</Typography>
      {category.posts.nodes.map(post => {
          return (
            <Card key={post.id} className={classes.card2}>
              <CardContent className={classes.cardContent2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    {post.featuredImage &&
                      <img src={post.featuredImage.node.sourceUrl} alt={post.title} width={60} className="margin-bottom-20" />
                    }
                    <Typography gutterBottom variant="h5" className={classes.cardTitle}>
                      {post.title}
                    </Typography>
                    </Grid>
                </Grid>                 
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6}>   
                    <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.textSecondary} gutterBottom>
                      {parse(post.excerpt)}
                    </Typography>
                    <Button component={Link} variant="outlined" href={`/services/${post.slug}`} className={classes.cardButton}>Read More</Button>
                  </Grid>
                  <Grid item xs={12} lg={6}>  
                    <Grid container spacing={3}>
                      {post.tags.nodes.map(tag => (
                        <Grid key={tag.id} item xs={6} lg={6}>   
                          <Typography variant="subtitle1" className={classes.tag}>{tag.name}</Typography>
                        </Grid>
                      ))} 
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
      <br />
      <Grid container spacing={3}>
        {category.projects.nodes.map(post => {
        const backColor = post.projectAcf.uiBackgroundColor ? post.projectAcf.uiBackgroundColor : '#E1F1ED'
        const backgroundColor = hexToRgba(backColor, 0.9)
        const color = post.projectAcf.uiTextColor ? post.projectAcf.uiTextColor : '#000000'
        const secondaryTextColor = hexToRgba(color, 0.7)
        const backgroundImage = `linear-gradient(to right, ${backgroundColor} 0%, ${backgroundColor} 50%, rgba(0,0,0,0.1)), url("${post.featuredImage && post.featuredImage.node.sourceUrl}")`

          return (
            <Grid item key={post.id} xs={12} lg={6}>
              <Card className={classes.card} style={{ backgroundImage, color }}>
                <CardActionArea component={Link} to={`/projects/${post.slug}`}>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" className={classes.cardTitle}>
                      {post.title}
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: secondaryTextColor }} component="p" className={classes.textSecondary} gutterBottom>
                      {parse(post.excerpt)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
  let tabsContent = []
  categories.forEach(category => {
    tabsContent.push({ text: category.node.name, component: renderPostsAndProjects(category.node) })
  });

  // console.log(tabsContent)
  return (
    <div className={`alignwider`}>
      <ScrollSpyTabs
        categories={categories}
        tabsInScroll={tabsContent}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    minHeight: 380,
    backgroundRepeat: "repeat-x",
    backgroundSize: "auto 512px",
    backgroundPosition: "right center",
    color: "#000",
    marginBottom: 16,
    boxShadow: '0 0.625rem 1.25rem 0 rgb(0 0 0 / 10%)'
  },
  cardContent: {
    width: '70%',
    paddingLeft: 32,
    minHeight: 380,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      background: 'rgba(246,246,246,1)'
    },
  },
  card2: {
    width: "100%",
    minHeight: 380,
    backgroundRepeat: "repeat-x",
    backgroundSize: "auto 512px",
    backgroundPosition: "right center",
    backgroundColor: '#f6f6f9',
    backgroundImage: `url("${Cardback}")`,
    color: "#000",
    marginBottom: 16,
    boxShadow: '0 0.625rem 1.25rem 0 rgb(0 0 0 / 10%)'
  },
  cardContent2: {
    padding: 36
  },
  cardTitle: {
    fontWeight: 500
  },
  textSecondary: {
    lineHeight: '1.5rem'
  },
  cardButton: {
    border: '2px solid #000',
    textTransform: 'none'
  },
  tag: {
    fontWeight: 500
  }
}));


export default Services;
