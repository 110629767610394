import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

const TechStack = props => {
  const [currentCategory, setCurrentCategory] = useState('Code')
  
  const { allWpTechnology: { technologies }} = useStaticQuery(graphql`
    query ourTechnoloies {
      allWpTechnology(sort: { fields: [date], order: ASC }) {
        technologies: edges {
          node {
            id
            title
            featuredImage {
              node {
                sourceUrl
              }
            }
            categories {
              nodes {
                name
                id
                slug
              }
            }
          }
        }
      }
    }
  `)

  const isEmpty = technologies.length === 0;
  const classes = useStyles();

  if (isEmpty) {
    return <span />
  }

  // console.log(events)
  let categories = ['Technologies']
  for (let index = 0; index < technologies.length; index++) {
    const tech = technologies[index].node;
    tech.categories.nodes.forEach(category => {
      categories.push(category.name)
    });
  }
  let uniqueCategories = [...new Set(categories)]
  return (
    <div className={`bg-muted padding-top-bottom-60`}>
      <div className={`${classes.root} alignwider`}>
        <h2 className={classes.title}>Ensuring quality and performance using these <span className="accent-text">Tools</span> and <span className="accent-text">Technologies</span>.</h2>
        {uniqueCategories.map(category => (
          <Chip
            key={category}
            label={category === 'Technologies' ? 'All' : category} 
            className={classes.chip}
            color={category === currentCategory ? 'primary' : 'default'}
            onClick={() => setCurrentCategory(category)}
          />
        ))}
        {uniqueCategories.map((category, index) => {
          if (category === currentCategory) {
            return (
              <Grid container spacing={2} key={`${category}-${index}`} className="p30-0">
                {technologies.map(technologyObj => {
                  const tech = technologyObj.node;
                  const techCategories = tech.categories.nodes.map(cat => { return cat.name });
                  if (techCategories.includes(category)) {
                    return (
                      <Grid item key={`${category}-${tech.id}-${index}`} xs={6} md={2} lg={2}>
                        <div className={classes.techGrid}>
                          <img src={tech.featuredImage.node.sourceUrl} alt={tech.title} className={classes.img} />
                        </div>
                      </Grid>
                    );
                  }
                  return <span key={tech.id} />;
                })}
              </Grid>
            )
          }
          return <span key={`${category}-${index}`} />;
        })}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // paddingTop: 50,
    // paddingBottom: 20,
    // overflowX: 'hidden',
  },
  title: {
    fontSize: '2.3rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8rem'
    },
  },
  chip: {
    marginRight: 10,
    marginBottom: 10,
    fontSize: 15,
    borderRadius: 16,
    height: 34,
    paddingLeft: 12,
    paddingRight: 12,
    // position: 'relative',
    // top: 0,
    transition: 'box-shadow ease 0.3s',
    cursor: 'pointer',
    '&:hover': {
      // top: '-2px'
      boxShadow: '0 0.625rem 1.25rem 0 rgb(0, 0, 0 , 0.1)',
    }
  },
  activeChip: {
    background: 'green !important'
  },
  img: {
    marginTop: 12,
    maxWidth: '80%',
    maxHeight: 72,
    padding: '12px 0'
  },
  techGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  secondaryText: {
    color: '#757575'
  }
}));


export default TechStack;
