import React from "react";
import parse from "html-react-parser"

import FeaturedPosts from '../FeaturedPosts';
// import PortfolioProjects from '../PortfolioProjects';
import Services from '../Services';
import Solutions from '../Solutions';
import UpcomingEvents from '../UpcomingEvents';
import ContactFormWrapper from '../ContactFormWrapper';
import ContactForm from '../ContactFormOnly';
// import ContactFormThree from '../ContactFormThree'
import OurTeam from '../OurTeam';
import TechStack from '../TechStack';

const BlockShortcode = props => {

  const { blockContent } = props;
  if (blockContent === '[showFeaturedPosts]') {
    return <FeaturedPosts />;
  }
  if (blockContent === "[showSolutions]") {
    return <Solutions />;
  }
  // if (blockContent === "[showPortfolioProjects]") {
  //   return <PortfolioProjects />;
  // }
  if (blockContent === "[showServices]") {
    return <Services />;
  }
  if (blockContent === '[showUpcomingEvents]') {
    return <UpcomingEvents />;
  }
  if (blockContent === '[showContactFormLinkedToPortfolio]') {
    return <ContactFormWrapper linkTo='portfolio' />;
  }
  if (blockContent === '[showContactFormLinkedToServices]') {
    return <ContactFormWrapper linkTo='services' />;
  }
  if (blockContent === '[showContactForm]') {
    return <ContactForm />;
  }
  if(blockContent === '[showOurTeam]') {
    return <OurTeam />;
  }
  if(blockContent === '[showTechnologyStack]') {
    return <TechStack />
  }
  return (
    <section itemProp="articleBody" class="wp-gatsby-block">{parse(blockContent)}</section>
  )
}

export default BlockShortcode;