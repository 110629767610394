import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import parse from "html-react-parser"


const FeaturedPortfolioItems = props => {
  const { allWpPost: { posts } } = useStaticQuery(graphql`
    query FeaturedBLogPostQuery {
      allWpPost(filter: {categories: {nodes: {elemMatch: {slug: {eq: "featured-posts"}}}}}, limit: 6) {
        posts: edges {
          node {
            id
            title
            slug
            uri
            excerpt
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `)
  const isEmpty = posts.length === 0;
  const classes = useStyles();

  if (isEmpty) {
    return <span />
  }
  // console.log(posts)
  return (
    <div className={`bg-muted padding-top-bottom-60`}>
      <div className={`${classes.root} alignwider`}>
        <h2>Featured Posts</h2>
        <Grid container spacing={5}>
          {posts.map(postObj => {
            const post = postObj.node
            return (
              <Grid item key={post.id} xs={12} lg={4}>
                <Card className={classes.card}>
                  <CardActionArea component={Link} to={post.uri}>
                    <CardMedia
                      className={classes.media}
                      image={post.featuredImage.node.sourceUrl}
                      title="Contemplative Reptile"
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" fontWeight="fontWeightMedium">
                        {post.title}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.textSecondary}>
                        {parse(post.excerpt)}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // paddingTop: 50,
    // paddingBottom: 50,
    // overflowX: 'hidden',
  },
  card: {
    position: 'relative',
    top: 0,
    transition: 'top ease 0.3s',
    boxShadow: '0 0.625rem 1.25rem 0 rgb(0, 0, 0 , 0.1)',
    '&:hover': {
      top: '-12px'
    }
  },
  media: {
    height: 280,
  },
  cardContent: {
    height: 172,
    marginBottom: 16,
    overflow: 'hidden'
  },
  textSecondary: {
    lineHeight: '1.5rem'
  }
}));


export default FeaturedPortfolioItems;
