import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import ContactForm from './ContactForm';

const ContactFormTwo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1} className="alignwide">
        <Grid item xs={12} lg={10}>
          <ContactForm />
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '10px 0px 20px 0px',
    overflowX: 'hidden',
  },
}));

export default ContactFormTwo;