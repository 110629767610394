import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import BlockForAll from "../components/blocks/BlockForAll"
import BlockShortcode from "../components/blocks/BlockShortcode"
import Seo from "../components/seo"
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

const PageTemplate = ({ data: { page } }) => {
  const { title, slug, blocks, pageSeoAcf } = page;
  return (
    <Layout>
      <Seo title={title} description={pageSeoAcf.metaDescription} schemaMarkup={pageSeoAcf.schemaMarkup } />
      {blocks &&
        <>
          {blocks.map(block => {
            const blockType = block.__typename;
            if (blockType === "WpCoreShortcodeBlock") {
              return <BlockShortcode pageSlug={slug} blockContent={block.saveContent} key={block.order} />
            }
            return (
              <BlockForAll pageSlug={slug} blockContent={block.saveContent} key={block.order} />
            );
          })}
        </>
      }
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      id
      title
      slug
      uri
      template {
        templateName
      }
      blocks {
        __typename
        name
        order
        isDynamic
        saveContent
      }
      pageSeoAcf {
        metaDescription
        schemaMarkup
      }
    }
  }
`

export default PageTemplate
