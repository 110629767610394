import React from "react";
import parse from "html-react-parser"

const BlockColumns = props => {
  const { blockContent } = props;
  return (
    <section itemProp="articleBody" className="wp-gatsby-block">{parse(blockContent)}</section>
  )
}

export default BlockColumns;